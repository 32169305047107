/* eslint-disable consistent-return */

import {
  getContentDeclaration,
  getLoggiLabel,
  generateLabel,
  getOrCreateRedispatchLabel
} from '../../api-rest';
import { LABEL_TYPE } from '../../app/enums';
import {
  CompanyDontAllowRedispatchError,
  LoggiLabelNotFoundError
} from './exceptions';
import { REDISPATCH_LABEL_ERROR_TYPE } from '../../constants';

export const LABEL_MISSING_TEXT = {
  [LABEL_TYPE.LABEL_TYPE_LOGGI]: 'Etiqueta Indisponível',
  [LABEL_TYPE.LABEL_TYPE_NF]: 'NF Indisponível',
  [LABEL_TYPE.LABEL_TYPE_CTE]: 'CTE Indisponível',
  [LABEL_TYPE.LABEL_TYPE_DECON]: 'Declaração de Conteúdo indisponível'
};

export const LABEL_PRINT_PACKAGE_CHECK = {
  [LABEL_TYPE.LABEL_TYPE_LOGGI]: 'Impressão de etiqueta Loggi',
  [LABEL_TYPE.LABEL_TYPE_REDISPATCH]: 'Impressão de etiqueta de Redespacho',
  [LABEL_TYPE.LABEL_TYPE_DECON]: 'Impressão de Declaração de Conteúdo'
};

export const LABEL_TYPE_FORMAT = {
  PDF: 1,
  ZPL: 2
};

/**
 * @typedef {Object} Label
 * @property {string} base64Pdf - The label in base64 format
 * @property {string} type - The LABEL_TYPE enum
 * @property {string} missingText - The string to show when the base64Pdf wasn't found
 * @property {string} packageCheckNotes - The note used on packageCheck
 * @property {string} packageCheckIdentifier - A barcode used to identify the packageCheck
 */
/**
 * This function is responsible for returning a label, with
 * the given info.
 *
 *
 * @param {string} type - The LABEL_TYPE enum
 * @param {string} [base64Pdf] - The label in base64 format, if it was found
 * @param {string} [packageCheckIdentifier] - Barcode of the label
 * @returns {Label}
 */
const buildLabel = ({ type, base64Pdf, packageCheckIdentifier }) => {
  return {
    base64Pdf,
    type,
    missingText: LABEL_MISSING_TEXT[type],
    packageCheckNotes: LABEL_PRINT_PACKAGE_CHECK[type],
    packageCheckIdentifier
  };
};

const getLoggiLabelBase64Pdf = async ({ type, pkg, enableGenerateLabelApi }) => {
  if (!pkg.loggiKey) {
    const errorMessage = 'Loggi key not found.';
    const mainLabel = buildLabel({ type });
    throw new LoggiLabelNotFoundError(errorMessage, mainLabel, pkg);
  }
  try {
    if (enableGenerateLabelApi) {
      const response = await generateLabel({
        packageIdentifier: pkg.loggiKey,
        isRedispatch: false,
        labelType: LABEL_TYPE_FORMAT.PDF
      });

      return response?.data?.labelInfo;
    }

    const response = await getLoggiLabel({
      packageIdentifier: pkg.loggiKey,
      companyId: pkg.company.id
    });

    return response?.data.label?.base64Pdf;
  } catch (err) {
    const mainLabel = buildLabel({ type });
    throw new LoggiLabelNotFoundError(err.message, mainLabel, pkg);
  }
};

const getRedispatchLabelBase64Pdf = async ({
  pkg,
  distributionCenterId,
  isEmergency,
  enableGenerateLabelApi
}) => {
  try {
    if (enableGenerateLabelApi) {
      const response = await generateLabel({
        packageIdentifier: pkg.loggiKey,
        isRedispatch: true,
        labelType: LABEL_TYPE_FORMAT.PDF
      });

      return {
        base64Pdf: response?.data?.labelInfo,
        packageCheckIdentifier: pkg.barcode
      };
    }

    const response = await getOrCreateRedispatchLabel({
      packageId: pkg.id,
      packageIdentifier: pkg.identifier,
      isEmergency,
      distributionCenterId,
      postingCardNumber: pkg.postingCard.number
    });
    const label = response?.data.label;
    return {
      base64Pdf: label?.base64Pdf,
      packageCheckIdentifier: label?.barcode
    };
  } catch (err) {
    const errorType = err?.response?.data?.errorType;
    const EMERGENCY_ERROR = 'REDISPATCH_LABEL_ERROR_TYPE_EMERGENCY_ONLY';

    if (errorType) {
      if (errorType === EMERGENCY_ERROR) {
        throw new CompanyDontAllowRedispatchError(err.message, pkg);
      }

      throw new Error(REDISPATCH_LABEL_ERROR_TYPE[errorType]);
    }

    throw err;
  }
};

const getContentDeclarationBase64Pdf = async ({ loggiKey }) => {
  const response = await getContentDeclaration({ loggiKey });
  return response?.data?.base64Pdf;
};

const loggiLabelHandler = async ({ type, pkg, enableGenerateLabelApi }) => {
  const base64Pdf = await getLoggiLabelBase64Pdf({
    type,
    pkg,
    enableGenerateLabelApi
  });

  return buildLabel({
    type,
    base64Pdf,
    packageCheckIdentifier: pkg.identifier
  });
};

const redispatchLabelHandler = async ({
  type,
  pkg,
  options: { distributionCenterId, isEmergency },
  enableGenerateLabelApi
}) => {
  const {
    base64Pdf,
    packageCheckIdentifier
  } = await getRedispatchLabelBase64Pdf({
    pkg,
    distributionCenterId,
    isEmergency,
    enableGenerateLabelApi
  });

  return buildLabel({ type, base64Pdf, packageCheckIdentifier });
};

const contentDeclarationHandler = async ({ type, pkg }) => {
  const base64Pdf = await getContentDeclarationBase64Pdf({
    loggiKey: pkg.loggiKey
  });
  return buildLabel({
    type,
    base64Pdf,
    packageCheckIdentifier: pkg.identifier
  });
};

/**
 * This function is responsible for returning the labelInfo of the given label type.
 * For every labelType addition, a new handler must be implemented,
 * the default being the LABEL_TYPE_LOGGI.
 *
 *
 * @param {string} type - The LABEL_TYPE enum
 * @param {object} pkg - The pkg info
 * @param {object} [options] - Options relative to specific labels
 * @returns {Label}
 */
export const getLabel = async ({
  type,
  pkg,
  options: { distributionCenterId, isEmergency },
  enableGenerateLabelApi
}) => {
  switch (type) {
    case LABEL_TYPE.LABEL_TYPE_LOGGI:
      return loggiLabelHandler({ type, pkg, enableGenerateLabelApi });
    case LABEL_TYPE.LABEL_TYPE_REDISPATCH:
      return redispatchLabelHandler({
        type,
        pkg,
        options: { distributionCenterId, isEmergency },
        enableGenerateLabelApi
      });
    case LABEL_TYPE.LABEL_TYPE_DECON:
      return contentDeclarationHandler({ type, pkg });
    default:
      return loggiLabelHandler({ type, pkg, enableGenerateLabelApi });
  }
};
