import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { LABEL_TYPE } from '../../app/enums';
import { getInfoFromPackageIdentifier } from '../../api-rest';
import { BarcodeReader } from '../../app/components/barcode-readers';
import handleRestAPIError from '../../app/utils/rest-api-request';
import DisambiguatePackageDialog from '../../app/components/disambiguate-package-dialog';
import { getLabel } from './label-handlers';
import {
  LoggiLabelNotFoundError,
  CompanyDontAllowRedispatchError
} from './exceptions';
import EmergencyRedispatchLabelDialog from './emergency-redispatch-label-dialog';
import { packageCheck } from '../../api';
import { GeolocationContext } from '../../geo';
import { APP_NAME, SWITCHES } from '../../constants';
import { extractPkgInfo } from '../organize/unit-handlers';
import { useFeature } from '../../app/hooks/use-feature';

export const MESSAGES = {
  UNAVAILABLE_LABEL: 'Etiqueta indisponível',
  IMPOSSIBLE_TO_PRINT_LABEL:
    'Eita, não é possível preparar esse pacote. Fale com o suporte.',
  REDISPATCH_LABEL_ERROR_TYPE_EMERGENCY_ONLY:
    'Só envie esse pacote para os Correios se for um caso de ' +
    'emergência e tiver a permissão do seu supervisor.',
  NOT_FOUND_IDENTIFIER:
    'Eita, você bipou um código de barras que não existe. ' +
    'Verifique se o código está correto e tente de novo.',
  INITIAL_PACKAGE_CHECK: 'Bipe na tela de preparar pacotes'
};

/**
 * Wrapper to a PackageReader that fetches PrepareInfo, every label type has
 * it's own handling
 *
 * @callback onSuccessCallback called when Prepare Info is successfully fetched
 * @callback onErrorCallback called when and error happens while fetching Receive Info
 *
 * @param {string} mainLabelType - Type of label to print
 * @param {string} [postingCard] - Selected or single postingCard of the dc
 * @param {function} [onChange] - Function executed on every change
 * @param {string} distributionCenterId - Type id of the current dc
 * @param {function} onSuccessCallback -
 * @param {function} onErrorCallback -
 *
 */
export default function PrepareInfoReader({
  onSuccessCallback,
  onErrorCallback,
  mainLabelType,
  onChange,
  postingCard,
  distributionCenterId
}) {
  const [geo] = React.useContext(GeolocationContext);
  const [loading, setLoading] = useState(false);
  const [
    packageToConfirmEmergencyRedispatch,
    setPackageToConfirmEmergencyRedispatch
  ] = useState(null);
  const [packagesToDisambiguate, setPackagesToDisambiguate] = useState([]);
  const enableGenerateLabelApi = useFeature(SWITCHES.enableGenerateLabelApi);

  const getPackageIdentifierInfo = async packageIdentifier => {
    try {
      const queryOriginalBarcode =
        mainLabelType === LABEL_TYPE.LABEL_TYPE_REDISPATCH;

      const response = await getInfoFromPackageIdentifier({
        packageIdentifier,
        queryOriginalBarcode
      });
      return response.data;
    } catch (err) {
      if (err?.response?.status === 404) {
        throw new Error(MESSAGES.NOT_FOUND_IDENTIFIER);
      }

      throw err;
    }
  };

  const extractPkgData = packageInfo => {
    const pkgInfo = extractPkgInfo(packageInfo);
    pkgInfo.postingCard = {
      number: postingCard?.number,
      name: postingCard?.name
    };
    return pkgInfo;
  };

  const handleSinglePackage = async (pkg, options) => {
    const mainLabel = await getLabel({
      type: mainLabelType,
      pkg,
      options,
      enableGenerateLabelApi
    });
    packageCheck(
      geo,
      mainLabel.packageCheckIdentifier,
      MESSAGES.INITIAL_PACKAGE_CHECK,
      APP_NAME
    ).catch(err => handleRestAPIError(err, () => {}));
    onSuccessCallback({
      mainLabel,
      pkg
    });
  };

  const handleMultiplePackages = pkgIdentifierInfo => {
    const packages = pkgIdentifierInfo.map(pkg => extractPkgData(pkg));
    setPackagesToDisambiguate(packages);
  };

  const handleReadError = err => {
    if (err instanceof LoggiLabelNotFoundError) {
      onSuccessCallback({
        mainLabel: err.label,
        pkg: err.pkg
      });
      return;
    }

    if (err instanceof CompanyDontAllowRedispatchError) {
      setPackageToConfirmEmergencyRedispatch(err.pkg);
      return;
    }

    const isCustomError = Object.values(MESSAGES).some(
      errorMsg => errorMsg === err?.message
    );

    if (isCustomError) {
      onErrorCallback(err.message);
      return;
    }

    handleRestAPIError(err, errorMsg => onErrorCallback(errorMsg));
  };

  const handleRead = async packageIdentifier => {
    try {
      setLoading(true);

      const pkgIdentifierInfo = await getPackageIdentifierInfo(
        packageIdentifier
      );

      if (pkgIdentifierInfo.info.length > 1) {
        handleMultiplePackages(pkgIdentifierInfo.info);
      } else {
        const pkg = extractPkgData(pkgIdentifierInfo.info[0]);

        const options = { distributionCenterId, isEmergency: false };
        await handleSinglePackage(pkg, options);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleReadError(err);
    }
  };

  const handleDialogCancel = () => {
    setPackagesToDisambiguate([]);
    onErrorCallback(MESSAGES.IMPOSSIBLE_TO_PRINT_LABEL);
  };

  const handleSelectPackage = async selectedPackage => {
    setLoading(true);
    setPackagesToDisambiguate([]);

    const options = { distributionCenterId, isEmergency: false };
    await handleSinglePackage(selectedPackage, options).catch(err =>
      handleReadError(err)
    );
    setLoading(false);
  };

  const handleEmergencyRedispatchCancel = () => {
    setPackageToConfirmEmergencyRedispatch(null);
    onErrorCallback(MESSAGES.REDISPATCH_LABEL_ERROR_TYPE_EMERGENCY_ONLY);
  };

  const handleEmergencyRedispatchConfirm = async () => {
    setLoading(true);

    const options = { distributionCenterId, isEmergency: true };
    await handleSinglePackage(
      packageToConfirmEmergencyRedispatch,
      options
    ).catch(err => handleReadError(err));

    setPackageToConfirmEmergencyRedispatch(null);
    setLoading(false);
  };

  return (
    <Box>
      <Box my={2.5}>
        <BarcodeReader
          onRead={handleRead}
          onChange={onChange}
          loading={loading}
          placeholder="Leia o código do pacote"
        />
      </Box>
      {packagesToDisambiguate.length > 0 && (
        <DisambiguatePackageDialog
          open
          onCancel={handleDialogCancel}
          onSelectPackage={handleSelectPackage}
          packages={packagesToDisambiguate}
        />
      )}
      {packageToConfirmEmergencyRedispatch && (
        <EmergencyRedispatchLabelDialog
          open
          onConfirm={handleEmergencyRedispatchConfirm}
          onCancel={handleEmergencyRedispatchCancel}
        />
      )}
    </Box>
  );
}

PrepareInfoReader.defaultProps = {
  mainLabelType: LABEL_TYPE.LABEL_TYPE_LOGGI,
  onChange: () => {},
  postingCard: {}
};

PrepareInfoReader.propTypes = {
  onSuccessCallback: PropTypes.func.isRequired,
  onErrorCallback: PropTypes.func.isRequired,
  mainLabelType: PropTypes.string,
  onChange: PropTypes.func,
  postingCard: PropTypes.shape({
    name: PropTypes.string,
    number: PropTypes.string
  }),
  distributionCenterId: PropTypes.number.isRequired
};
